<template>
  <v-expand-transition>
    <FeedCard class="pb-3">
      <v-responsive :aspect-ratio="600 / 415">
        <v-card-title
          class="text-body-1 font-weight-medium"
          v-text="title"
        />

        <div
          class="px-0"
        >
          <div
            v-if="!loading && !computedUsers.length"
            slot="no-users"
            class="text-h6 grey--text text--lighten-1 text-center my-4"
          >
            <div v-if="hasFollowed">
              <v-icon
                color="grey lighten-1"
                x-large
              >
                mdi-check-circle
              </v-icon>
              <br>
              All done!
            </div>
            <div v-else>
              No users
            </div>
          </div>

          <swiper-container
            class="swiper sg-slide-group"
            v-bind="swiperOptions"
          >
            <slot
              v-if="loading"
              name="loading"
            >
              <swiper-slide
                v-for="i in [1, 2]"
                :key="i"
                class="ml-3"
              >
                <v-card
                  flat
                  height="100%"
                >
                  <v-responsive :aspect-ratio="4 / 3">
                    <v-skeleton-loader
                      height="100%"
                      type="image"
                    />
                  </v-responsive>
                  <div
                    class="py-3"
                    style="width: 75%"
                  >
                    <v-skeleton-loader type="text" />
                    <v-skeleton-loader type="text" />
                    <v-skeleton-loader type="text" />
                  </div>
                </v-card>
              </swiper-slide>
            </slot>

            <slot
              v-if="!loading && computedUsers.length"
              name="loaded"
            >
              <swiper-slide
                v-for="(user, index) in computedUsers"
                :key="user.id"
                :class="{
                  'ml-3': !index,
                  'mr-3': index === users.length - 1
                }"
              >
                <UserCard
                  :show-follow="showFollow"
                  :follow-action="followAction"
                  :flat="true"
                  :user="user"
                  @follow-start="$emit('follow-start', user, index)"
                  @follow-complete="followComplete"
                />
              </swiper-slide>
            </slot>
          </swiper-container>
        </div>
      </v-responsive>
    </FeedCard>
  </v-expand-transition>
</template>

<script>
/**
 * @displayName User slider
 */

import { register as registerSwiper } from 'swiper/element/bundle';

registerSwiper();

export default {
  name: 'UserSlider',

  props: {
    users: {
      type: Array,
      required: true,
    },

    showFollow: {
      type: Boolean,
      default: true,
    },

    cardWidth: {
      type: String,
      default: '100%',
    },

    /**
     * Optionally set follow method as dependency injection
     */
    followAction: {
      type: Function,
      default: null,
    },

    height: {
      type: String,
      default: '360px',
    },

    loading: {
      type: Boolean,
      default: false,
    },

    title: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      followed: [],
      swiperOptions: {
        'space-between': 12,
        'slides-per-view': 1.6,
        // Prevent Swiper touch events triggering on parent.
        'events-prefix': 'swiper-',
      },
    };
  },

  computed: {
    computedUsers() {
      return this.users.filter((user) => !this.followed.includes(user.id));
    },

    hasFollowed() {
      return this.followed.length;
    },
  },

  methods: {
    followComplete(user, index) {
      this.followed.push(user.id);
      this.$emit('follow-complete', user, index);
    },
  },
};
</script>

<style lang="scss">
// Make v-skeleton-loader responsive. See:
// https://github.com/vuetifyjs/vuetify/issues/11771#issuecomment-659361353
.v-skeleton-loader.v-skeleton-loader--is-loading {
  .v-skeleton-loader__image {
    height: 100%;
  }
}

// SwiperJS uses z-index: 1, reset it and isolate it to avoid conflicts.
swiper-container {
  z-index: auto !important;
  isolation: isolate;
}
</style>
