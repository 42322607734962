<template>
  <v-slide-y-transition
    hide-on-leave
  >
    <FeedCard
      v-if="streams.length"
      class="pl-2 pt-3 pb-2"
    >
      <swiper-container
        id="streams"
        class="swiper sg-slide-group"
        v-bind="swiperOptions"
      >
        <swiper-slide
          v-for="stream in streams"
          :key="stream.id"
        >
          <div
            class="text-center"
          >
            <Avatar
              :user="stream.user"
              :size="60"
              :to="stream.$route"
            />

            <router-link
              class="text-decoration-none"
              :to="stream.$route"
            >
              <div class="text-caption grey--text font-weight-medium pt-1">
                {{ stream.user.username }}
              </div>
            </router-link>
          </div>
        </swiper-slide>
      </swiper-container>
    </FeedCard>
  </v-slide-y-transition>
</template>

<script>
import { register as registerSwiper } from 'swiper/element/bundle';

import { Stream } from 'pwa/models';

registerSwiper();

/**
 * The Live Now Banner
 *
 * @displayName The Live Now Banner
 */
export default {
  name: 'TheLiveNowCard',

  data() {
    return {
      swiperOptions: {
        'slides-per-view': 'auto',
        // Prevent Swiper touch events from triggering on parent.
        'events-prefix': 'swiper-',
      },
    };
  },

  computed: {
    streams: () => Stream.getters('allUniqueByUser'),
  },
};
</script>

<style lang="scss" scoped>

swiper-slide { width: 95px; }
.text-caption {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
