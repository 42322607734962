export class BaseGuard {
  constructor(to, from, next, user) {
    this.to = to;
    this.from = from;
    this.next = next;
    this.user = user;
  }

  /**
   * Return true if the user can access the route
   */
  canNavigate() { // eslint-disable-line class-methods-use-this
    throw new Error('The method `canNavigate` must be defined on this guard');
  }

  /**
   * On guard validation fail, abort the request. If this is the
   * first route accessed, redirect to the index.
   */
  failCallback() {
    const next = this.from.name ? false : { name: 'index' };
    this.next(next);
  }
}

export class AnonymousOnly extends BaseGuard {
  canNavigate() {
    return !this.user.is_authenticated;
  }
}

export class IsNotEnabledUser extends BaseGuard {
  canNavigate() {
    return !(this.user.is_authenticated && this.user.status === 'enabled');
  }
}

export class IsEnabled extends BaseGuard {
  canNavigate() {
    return this.user.is_authenticated && this.user.status === 'enabled';
  }

  /**
   * Redirect the user to the loginOrRegister page and append
   * the current route as the query parameter "next"
   */
  failCallback() {
    this.next({
      name: 'loginOrRegister',
      query: { next: this.to.fullPath },
    });
  }
}
