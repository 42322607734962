import { ValidationObserver, ValidationProvider } from 'vee-validate';
import Vue from 'vue';
import Nl2br from 'vue-nl2br';
import draggable from 'vuedraggable';

import Avatar from './Avatar';
import BadgedIcon from './BadgedIcon';
import Comment from './Comment';
import CommentForm from './CommentForm';
import ConfirmPageLeave from './ConfirmPageLeave';
import ConversationListItem from './ConversationListItem';
import FeedCard from './FeedCard';
import FollowButton from './FollowButton';
import HideButton from './HideButton';
import LikeButton from './LikeButton';
import MessageBubble from './MessageBubble';
import Notification from './Notification';
import PasswordField from './PasswordField';
import Post from './Post';
import PostControlSheet from './PostControlSheet';
import PostPrivacyEditSheet from './PostPrivacyEditSheet';
import PostPrivacyInfoSheet from './PostPrivacyInfoSheet';
import ReadMore from './ReadMore';
import SgImg from './SgImg';
import SgSheet from './SgSheet';
import SgSlideGroup from './SgSlideGroup';
import Stream from './Stream';
import TextAreaForm from './TextAreaForm';
import TheAppUpdateSnackbar from './TheAppUpdateSnackbar';
import TheBottomNavigation from './TheBottomNavigation';
import TheBottomNavigationMessageButton
  from './TheBottomNavigationMessageButton';
import TheFeed from './TheFeed';
import TheGallery from './TheGallery';
import TheIndexFilters from './TheIndexFilters';
import TheIndexPageFilterSheet from './TheIndexPageFilterSheet';
import TheLiveNowCard from './TheLiveNowCard';
import TheMembersOnlySheet from './TheMembersOnlySheet';
import TheNavAppBar from './TheNavAppBar';
import TheNotificationButton from './TheNotificationButton';
import TheOfflineBar from './TheOfflineBar';
import ThePageLoader from './ThePageLoader';
import TheProfileDrawer from './TheProfileDrawer';
import ThePromoGallery from './ThePromoGallery';
import ThePromoVideo from './ThePromoVideo';
import TheRenewSubscriptionSheet from './TheRenewSubscriptionSheet';
import TheTippingDialog from './TheTippingDialog';
import TheToaster from './TheToaster';
import ThumbnailGrid from './ThumbnailGrid';
import UserCard from './UserCard';
import UserListSheet from './UserListSheet';
import UserSearch from './UserSearch';
import UserSlider from './UserSlider';
import UserTypingIndicator from './UserTypingIndicator';
import VideoPlayer from './VideoPlayer';

import SgGallery from 'contrib/SgGallery';
import { TheBaseLayout } from 'pwa/layouts';

export const loadComponents = () => {
  // 3rd party
  Vue.component('Draggable', draggable);
  Vue.component('Nl2br', Nl2br);
  Vue.component('ValidationObserver', ValidationObserver);
  Vue.component('ValidationProvider', ValidationProvider);

  // App components
  Vue.component('Avatar', Avatar);
  Vue.component('BadgedIcon', BadgedIcon);
  Vue.component('Comment', Comment);
  Vue.component('CommentForm', CommentForm);
  Vue.component('ConfirmPageLeave', ConfirmPageLeave);
  Vue.component('ConversationListItem', ConversationListItem);
  Vue.component('FeedCard', FeedCard);
  Vue.component('FollowButton', FollowButton);
  Vue.component('HideButton', HideButton);
  Vue.component('LikeButton', LikeButton);
  Vue.component('MessageBubble', MessageBubble);
  Vue.component('Notification', Notification);
  Vue.component('PasswordField', PasswordField);
  Vue.component('Post', Post);
  Vue.component('PostControlSheet', PostControlSheet);
  Vue.component('PostPrivacyEditSheet', PostPrivacyEditSheet);
  Vue.component('PostPrivacyInfoSheet', PostPrivacyInfoSheet);
  Vue.component('ReadMore', ReadMore);
  Vue.component('SgGallery', SgGallery);
  Vue.component('SgImg', SgImg);
  Vue.component('SgSheet', SgSheet);
  Vue.component('SgSlideGroup', SgSlideGroup);
  Vue.component('Stream', Stream);
  Vue.component('TextAreaForm', TextAreaForm);
  Vue.component('TheAppUpdateSnackbar', TheAppUpdateSnackbar);
  Vue.component('TheBaseLayout', TheBaseLayout);
  Vue.component('TheBottomNavigation', TheBottomNavigation);
  Vue.component(
    'TheBottomNavigationMessageButton',
    TheBottomNavigationMessageButton,
  );
  Vue.component('TheFeed', TheFeed);
  Vue.component('TheGallery', TheGallery);
  Vue.component('TheIndexFilters', TheIndexFilters);
  Vue.component('TheIndexPageFilterSheet', TheIndexPageFilterSheet);
  Vue.component('TheLiveNowCard', TheLiveNowCard);
  Vue.component('TheMembersOnlySheet', TheMembersOnlySheet);
  Vue.component('TheNavAppBar', TheNavAppBar);
  Vue.component('TheNotificationButton', TheNotificationButton);
  Vue.component('TheOfflineBar', TheOfflineBar);
  Vue.component('ThePageLoader', ThePageLoader);
  Vue.component('TheProfileDrawer', TheProfileDrawer);
  Vue.component('ThePromoGallery', ThePromoGallery);
  Vue.component('ThePromoVideo', ThePromoVideo);
  Vue.component('TheRenewSubscriptionSheet', TheRenewSubscriptionSheet);
  Vue.component('TheTippingDialog', TheTippingDialog);
  Vue.component('TheToaster', TheToaster);
  Vue.component('ThumbnailGrid', ThumbnailGrid);
  Vue.component('UserCard', UserCard);
  Vue.component('UserListSheet', UserListSheet);
  Vue.component('UserSearch', UserSearch);
  Vue.component('UserSlider', UserSlider);
  Vue.component('UserTypingIndicator', UserTypingIndicator);
  Vue.component('VideoPlayer', VideoPlayer);
};

export {
  Avatar,
  BadgedIcon,
  Comment,
  CommentForm,
  ConfirmPageLeave,
  ConversationListItem,
  FeedCard,
  FollowButton,
  HideButton,
  LikeButton,
  MessageBubble,
  TheNavAppBar,
  Notification,
  PasswordField,
  Post,
  PostControlSheet,
  PostPrivacyEditSheet,
  PostPrivacyInfoSheet,
  ReadMore,
  SgGallery,
  SgImg,
  SgSheet,
  SgSlideGroup,
  Stream,
  TextAreaForm,
  TheAppUpdateSnackbar,
  TheBaseLayout,
  TheBottomNavigation,
  TheBottomNavigationMessageButton,
  TheFeed,
  TheGallery,
  TheIndexFilters,
  TheIndexPageFilterSheet,
  TheLiveNowCard,
  TheMembersOnlySheet,
  TheNotificationButton,
  TheOfflineBar,
  ThePageLoader,
  TheProfileDrawer,
  ThePromoGallery,
  ThePromoVideo,
  TheRenewSubscriptionSheet,
  TheTippingDialog,
  TheToaster,
  ThumbnailGrid,
  UserCard,
  UserListSheet,
  UserSearch,
  UserSlider,
  UserTypingIndicator,
  VideoPlayer,
};
export default null;
