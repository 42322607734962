<template>
  <v-container
    fluid
    class="container-max-width fill-height pa-0 ma-0"
  >
    <v-row
      class="ma-0 pa-0"
      align="center"
      no-gutters
    >
      <v-col
        :cols="leftCols"
      >
        <slot name="left">
          <v-btn
            class="back-btn"
            icon
            @click="back($router)"
          >
            <v-icon>mdi-chevron-left</v-icon>
          </v-btn>
        </slot>
      </v-col>

      <v-col :cols="centerCols">
        <slot name="center" />
      </v-col>

      <v-col :cols="rightCols">
        <slot name="right" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'TheNavAppBar',

  props: {
    leftCols: {
      type: [Number, String],
      default: 2,
    },

    centerCols: {
      type: [Number, String],
      default: 8,
    },

    rightCols: {
      type: [Number, String],
      default: 2,
    },
  },

  methods: {
    ...mapActions('router', ['back']),
  },
};
</script>
