<template>
  <video
    ref="videoPlayer"
    class="video-js vjs-big-play-centered"
  />
</template>

<script>
import videojs from 'video.js';
import 'video.js/dist/video-js.css';

/**
 * A wrapper of video.js
 * @displayName Video player
 */
export default {
  name: 'VideoPlayer',

  props: {
    /**
     * VideoJS options. See: https://docs.videojs.com/tutorial-options.html
     */
    options: {
      type: Object,
      default() {
        return {};
      },
    },
  },

  data() {
    return {
      player: null,
      overrideNative: typeof MediaSource !== 'undefined',
    };
  },

  async mounted() {
    const self = this;
    const options = {
      preload: 'none',
      ...this.options,
      html5: {
        ...this.options.html5,
        vhs: {
          overrideNative: this.overrideNative,
          ...this.options.html5?.vhs,
        },
      },
    };
    this.player = await videojs(
      this.$refs.videoPlayer,
      options,
      function onPlayerReady() {
        if (self.overrideNative) {
          const currentSource = this.currentSource();
          const currentSourceUrl = new URL(currentSource.src);

          this.tech(1).vhs?.xhr.onRequest((requestOptions) => {
            const url = new URL(requestOptions.uri);
            if (url.hostname === currentSourceUrl.hostname) {
              // If requesting to our media storage, replace the search params
              // with the currentSource params to copy any signature
              // (which should be signed based on url prefix to
              // allow this to work if same prefix).
              url.search = currentSourceUrl.search;
              requestOptions.uri = url.toString();
            }
          });
        }
        self.$emit('ready', this);
      },
    );
    this.player.on('error', () => {
      this.$emit('error', this.player.error());
    });
  },

  beforeDestroy() {
    if (this.player) {
      this.player.dispose();
    }
  },
};
</script>
