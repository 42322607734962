<template>
  <div
    fluid
    class="overflow-hidden"
  >
    <v-row dense>
      <v-col
        v-for="(image, index) in images.slice(indexStart)"
        :key="index"
        cols="4"
      >
        <sg-img
          v-bind="image"
          class="cursor-pointer grey lighten-3"
          :aspect-ratio="1"
          @click="clickImage(index + indexStart)"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
/**
 * Thumbnail Grid
 * @displayName Thumbnail Grid
 */
export default {
  name: 'ThumbnailGrid',

  props: {
    /**
     * A list of image URLs
     */
    images: {
      type: Array,
      default: () => [],
    },

    /**
     * The start of the index
     */
    indexStart: {
      type: Number,
      default: 0,
    },
  },

  methods: {
    clickImage(index) {
      /**
       * Triggers when a thumbnail is clicked
       *
       * @property {number} index - the image number that was clicked
       */
      this.$emit('click-image', index);
    },
  },
};
</script>
