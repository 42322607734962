import VueRouter from 'vue-router';

const { isNavigationFailure, NavigationFailureType } = VueRouter;

export class APIError extends Error {
  message = 'An unknown API error has occurred.';

  name = 'APIError';

  constructor(response) {
    super();
    let code; // DRF Error code (e.g. not_authenticated)
    if (response && response.data instanceof Object) {
      const { data } = response;
      this.message = data.message;
      ({ code } = data);
    }
    this.code = code;
    this.response = response;
  }
}

export class InvalidError extends APIError {
  name = 'InvalidError';

  constructor(response) {
    super(response);
    this.errors = {};
    if (response.data.errors && response.data.errors instanceof Object) {
      Object.entries(response.data.errors).forEach(([field, errors]) => {
        const _errors = errors instanceof Array ? errors : [errors];
        this.errors[field] = _errors.map(({ message }) => message);
      });
    }
  }
}

export class NetworkError extends APIError {
  message = 'A network error has occurred.';

  name = 'NetworkError';
}

export class NotFoundError extends APIError {
  name = 'NotFoundError';

  constructor(response) {
    super(response);
    this.message = `${response.config.url} not found`;
  }
}

export class NotAuthenticatedError extends APIError {
  message = 'This action requires authentication.';

  name = 'NotAuthenticatedError';
}

export class PaymentRequiredError extends APIError {
  message = 'A payment or financial information is required.';

  name = 'PaymentRequiredError';
}

export class RequestCancelled extends APIError {
  message = 'The request has been cancelled.';

  name = 'RequestCancelled';
}

export class ServiceUnavailableError extends APIError {
  message = 'A required service is currently unavailable.';

  name = 'ServiceUnavailableError';
}

export class StreamError extends Error {
  name = 'StreamError';
}

export class SubscriptionExpiredError extends APIError {
  name = 'SubscriptionExpiredError';
}

export class TimeoutError extends APIError {
  message = 'The request has timed out';

  name = 'TimeoutError';
}

export class UserBlockedError extends APIError {
  message = 'You have been blocked.';

  name = 'UserBlockedError';
}

// Configures global exception handling
export function errorHandler(err, vm) {
  // Suppress unjustified aborted navigation errors raised
  // by vue-router guards.
  if (isNavigationFailure(err, NavigationFailureType.abort)) {
    return null;
  }

  if (err instanceof NotAuthenticatedError) {
    return vm.$router.replace({
      name: 'loginOrRegister',
      query: { next: vm.$route.fullPath },
    });
  }
  if (err instanceof TimeoutError) {
    return vm.$toast('Request timed out', vm.$toast.ERROR);
  }
  if (err instanceof UserBlockedError) {
    return vm.$toast(err.message, vm.$toast.ERROR);
  }
  if (err instanceof SubscriptionExpiredError) {
    return vm.$store.dispatch('auth/toggleRenewSubscriptionSheet', true);
  }
  throw err;
}
