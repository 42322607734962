<template>
  <v-autocomplete
    v-model="selection"
    :autofocus="autofocus"
    :item-value="itemValue"
    :items="items"
    :loading="loading"
    :multiple="multiple"
    :search-input.sync="term"
    append-icon=""
    autocapitalize="off"
    autocomplete="off"
    chips
    dense
    eager
    full-width
    hide-details
    hide-no-data
    hide-selected
    item-text="username"
    label="Search SG"
    outlined
    spellcheck="false"
    prepend-inner-icon="mdi-magnify"
    rounded
    @blur="$emit('blur', $event)"
    @input="$emit('input', $event)"
  >
    <template #selection="data">
      <v-chip
        :input-value="data.selected"
        close
        small
        v-bind="data.attrs"
        @click:close="remove(data.item)"
        @click="data.select"
      >
        <v-avatar left>
          <v-img :src="data.item.avatar" />
        </v-avatar>
        {{ data.item.username }}
      </v-chip>
    </template>

    <template #item="data">
      <v-list-item-avatar>
        <img :src="data.item.avatar">
      </v-list-item-avatar>
      <v-list-item-content>
        <v-list-item-title>{{ data.item.username }}</v-list-item-title>
      </v-list-item-content>
    </template>
  </v-autocomplete>
</template>

<script>
import { debounce } from 'lodash-es';
import { mapActions } from 'vuex';

import { RequestCancelled } from 'pwa/exceptions';
/**
 * @displayName User search
 */
export default {
  name: 'UserSearch',

  props: {
    /**
     * Selection value
     */
    value: {
      type: [Number, String, Array],
      default: null,
      required: false,
    },

    /**
     * searches against all users when true, only followees and
     * models when false
     */
    allUsers: {
      type: Boolean,
      default: true,
    },

    /**
     * Populates autocomplete with existing items / suggestions
     */
    initialItems: {
      type: Array,
      default: () => [],
      required: false,
    },

    /**
     * The object property to be used as the selection value
     */
    itemValue: {
      type: String,
      default: 'id',
      required: false,
    },

    /**
     * An async function that accepts a search term and
     * resolves a list of results
     */
    searchAction: {
      type: Function,
      default: null,
    },

    /**
     * Enables multiple selections
     */
    multiple: {
      type: Boolean,
      default: false,
    },

    /**
     * Enables autofocus
     */
    autofocus: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      loading: false,
      items: [],
      selection: null,
      term: '',
    };
  },

  computed: {
    computedSearchAction() {
      return this.searchAction || this.defaultSearchAction;
    },
  },

  watch: {
    term: debounce(async function term(value) {
      if (!value || value.length < 3) {
        return;
      }
      this.loading = true;
      try {
        this.items = this.items.concat(await this.computedSearchAction(value));
      } finally {
        this.loading = false;
      }
    }, 200),
  },

  created() {
    this.items = this.initialItems;
  },

  methods: {
    remove(item) {
      if (this.multiple) {
        const index = this.selection.indexOf(item.id);
        if (index >= 0) this.selection.splice(index, 1);
      } else {
        this.selection = null;
      }
    },

    async defaultSearchAction(term) {
      try {
        const { allUsers } = this;
        const { data } = await this.findUsers({ term, allUsers });
        return data;
      } catch (e) {
        if (e instanceof RequestCancelled) {
          return [];
        }
        throw e;
      }
    },

    ...mapActions('search', ['findUsers']),
  },
};
</script>
