<template>
  <v-snackbar
    v-if="snackbarConfig"
    id="the-toaster"
    v-model="active"
    class="d-flex ma-2 pa-0"
    v-bind="snackbarConfig"
  >
    <v-container
      fluid
      class="text-center ma-0 pa-0"
    >
      <!-- eslint-disable vue/no-v-html -->
      <div
        class="text-body-1 font-weight-medium"
        v-html="snackbarConfig.html"
      />
    </v-container>
  </v-snackbar>
</template>

<script>
import { mapGetters } from 'vuex';

/**
 * @displayName The toaster
 */
export default {
  name: 'TheToaster',

  data() {
    return {
      active: false,
    };
  },

  computed: {
    ...mapGetters('toast', ['snackbarConfig']),
  },

  watch: {
    snackbarConfig() {
      this.active = true;
    },
  },
};
</script>
