<template>
  <div>
    <v-dialog
      class="tipping-dialog"
      :value="show"
      max-width="550px"
      color="#212121"
      @click:outside="closeTippingDialog"
    >
      <v-container
        fluid
        class="tipping-container"
        @keyup.esc="closeTippingDialog"
      >
        <v-row>
          <v-col
            cols="6"
            class="d-flex pa-0"
          >
            <div class="back ml-0">
              <v-btn
                v-if="customAmount"
                icon
                @click="showPresets"
              >
                <v-icon color="#9e9e9e">
                  mdi-chevron-left
                </v-icon>
              </v-btn>
            </div>
          </v-col>
          <v-col
            cols="6"
            class="d-flex justify-end pa-0"
          >
            <div class="close mr-0">
              <v-btn
                icon
                @click="closeTippingDialog"
              >
                <v-icon color="#9e9e9e">
                  mdi-close
                </v-icon>
              </v-btn>
            </div>
          </v-col>
        </v-row>

        <v-row v-if="showError">
          <v-col>
            <v-alert
              v-model="showError"
              type="error"
              dismissible
            >
              {{ errorMessage }}
            </v-alert>
          </v-col>
        </v-row>

        <v-row>
          <v-col class="text-center text-uppercase pt-0">
            <h1
              class="text-subtitle-2 white--text font-weight-bold"
            >
              <span v-if="customAmount">Enter amount to tip</span>
              <span v-if="!customAmount">Leave a Tip</span>
            </h1>
          </v-col>
        </v-row>

        <v-row
          v-if="!customAmount"
          class="amounts"
        >
          <v-col
            v-for="amount_option in [1, 2, 5]"
            :key="amount_option"
            class="text-center"
          >
            <v-btn
              :class="'amount amount-' + amount_option"
              :color="amount === amount_option ? 'green' : 'white'"
              :disabled="!currentUser || currentUser.balance < amount_option"
              :outlined="amount != amount_option"
              @click="amount = amount_option"
            >
              ${{ amount_option }}
            </v-btn>
          </v-col>
        </v-row>

        <v-row
          v-if="!customAmount"
          class="amounts"
        >
          <v-col
            v-for="amount_option in [10, 15]"
            :key="amount_option"
            class="text-center"
          >
            <v-btn
              :class="'amount amount-' + amount_option"
              :color="amount === amount_option ? 'green' : 'white'"
              :disabled="!currentUser || currentUser.balance < amount_option"
              :outlined="amount != amount_option"
              @click="amount = amount_option"
            >
              ${{ amount_option }}
            </v-btn>
          </v-col>

          <v-col class="text-center">
            <v-btn
              outlined
              class="custom"
              @click="customAmount = true"
            >
              +
            </v-btn>
          </v-col>
        </v-row>

        <v-row
          v-if="customAmount"
          class="custom-amount-row"
        >
          <v-col
            cols="2"
            class="grey--text darken-1 text-h4 text-center pt-5"
          >
            $
          </v-col>
          <v-col
            cols="10"
            class="d-flex align-center"
          >
            <v-text-field
              v-model="amount"
              single-line
              outlined
              background-color="white"
              color="primary"
              type="number"
              :messages="customAmountMessages"
              @change="validateCustomAmount"
              @keyup="validateCustomAmount"
            />
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <hr>
          </v-col>
        </v-row>
        <v-row>
          <v-col
            class="
              d-flex align-center text-caption text-uppercase
              font-weight-bold pr-0 py-1
            "
            cols="8"
          >
            <span
              class="white--text font-weight-bold mr-1"
            >
              Your balance:
            </span>
            <span
              v-if="currentUser"
              class="balance primary--text"
            >
              {{ numeral(currentUser.balance).format('$0,0.00') }}
            </span>
          </v-col>

          <v-col
            class="d-flex align-center text-right pl-0 py-1"
            cols="4"
          >
            <a
              class="add-more text-caption text-uppercase font-weight-bold"
              @click="comingSoon = true"
            >
              + Add more
            </a>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="pb-0">
            <h3
              class="
                text-caption
                text-uppercase
                white--text
                font-weight-bold
                mb-2
              "
            >
              Write a comment
            </h3>

            <v-textarea
              v-model="text"
              label="Type your comment..."
              name="message"
              filled
              background-color="white"
              dense
              no-resize
              height="100px"
            />
          </v-col>
        </v-row>

        <v-row>
          <v-col class="pt-0 pb-0">
            <v-radio-group
              v-model="isPublic"
              column
              class="pa-0 ma-0"
            >
              <v-radio
                label="Display tip on SG"
                :value="true"
                color="primary"
                class="privacy-public"
              />
              <v-radio
                label="Make anonymous"
                :value="false"
                color="primary"
                class="privacy-anonymous"
              />
            </v-radio-group>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-btn
              class="cta"
              color="primary"
              width="100%"
              @click="send"
            >
              Tip {{ formattedRecipients }}
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-dialog>

    <v-snackbar
      v-model="comingSoon"
    >
      This feature is coming soon!

      <v-btn
        color="pink"
        text
        @click="comingSoon = false"
      >
        Close
      </v-btn>
    </v-snackbar>
  </div>
</template>

<script>
import numeral from 'numeral';
import { mapGetters, mapActions } from 'vuex';

/**
 * The Tipping Dialog
 * @displayName The Tipping Dialog
 */
export default {
  props: {
    tipAction: {
      type: Function,
      async default() {
        const response = await this.$store.dispatch('entities/tips/tip', {
          amount: this.amount,
          public: this.isPublic,
          text: this.text,
        });
        return response;
      },
    },
  },

  data() {
    return {
      amount: 1,
      comingSoon: false,
      customAmount: false,
      customAmountMessages: '',
      errorMessage: 'Error sending tip',
      isPublic: true,
      showError: false,
      text: '',
    };
  },

  computed: {
    show() {
      return this.showTippingDialog;
    },

    formattedRecipients() {
      const { recipients } = this;

      if (!recipients) {
        return '';
      }

      if (recipients.length === 1) {
        return recipients[0].username;
      }

      if (recipients.length === 2) {
        return `${recipients[0].username} & ${recipients[1].username}`;
      }

      return `${recipients[0].username} & ${recipients.length - 1} others`;
    },

    ...mapGetters('auth', ['currentUser']),
    ...mapGetters('entities/tips', ['recipients', 'showTippingDialog']),
  },

  watch: {
    showTippingDialog() {
      this.amount = 1;
      this.customAmount = false;
      this.customAmountMessages = '';
      this.showError = false;
    },
    customAmount() {
      this.validateCustomAmount();
    },
  },

  methods: {
    numeral,

    showPresets() {
      if (![1, 2, 5, 10, 15].includes(Number(this.amount))) {
        this.amount = 1;
      }
      this.customAmount = false;
    },

    validateCustomAmount() {
      if (!this.amount || Number.isNaN(this.amount)) {
        this.customAmountMessages = 'Please enter a number';
      } else {
        this.customAmountMessages = '';
      }
    },

    async send() {
      if (this.customAmount) {
        this.validateCustomAmount();

        if (this.customAmountMessages !== '') {
          return;
        }
      }

      let response;

      try {
        response = await this.tipAction();
      } catch (error) {
        this.showError = true;
        return;
      }

      if (response.status !== 201) {
        this.showError = true;
        return;
      }

      await this.closeTippingDialog();
    },

    ...mapActions('entities/tips', ['closeTippingDialog']),
  },
};
</script>

<style scoped lang="scss">
.tipping-container {
  background-color: var(--v-darkgray-base);
  border: 10px solid var(--v-lightgray-base);

  h1 {
    letter-spacing: 2px !important;
  }

  .amounts {
    .col {
      padding: 10px;

      > button {
        border: 1px solid grey;

        &.v-btn--disabled {
          color: var(--v-disabledgray-base) !important;
        }
      }
    }

    .v-btn {
      color: var(--v-lightgray-base);

      &.custom {
        color: var(--v-primary-base);
      }
    }
  }

  hr {
    border: 1px solid var(--v-lightgray-base);
  }
}
</style>

<style lang="scss">
.tipping-container {
  .amounts .amount:not(.v-btn--disabled) .v-btn__content {
    color: white !important;
  }

  .theme--light.v-label {
    color: white;
    letter-spacing: 1px;
    text-transform: uppercase;
  }

  .theme--light.v-messages {
    color: var(--v-primary-base);
  }

  .v-input--selection-controls:not(.v-input--hide-details) .v-input__slot {
    margin-bottom: 0;
  }

  .v-input--selection-controls .v-input__control {
    width: 100%;
  }

  .v-input--selection-controls.v-input .v-label {
    font-size: 14px;
  }

  .v-radio {
    background-color: var(--v-shadedgray-base);
    padding: 10px;
    width: 100%;

    @media screen and (min-width: 600px) {
      padding: 20px;
    }

    .theme--light.v-icon {
      color: var(--v-icongray-base);
    }
  }
}
</style>
