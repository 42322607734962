<template>
  <v-card
    :disabled="pendingFollow"
    :flat="flat"
    :outlined="outlined"
    :ripple="false"
    :to="user.$route"
    :width="width"
  >
    <div style="position: relative">
      <sg-img
        :aspect-ratio="4 / 3"
        :src="user.profile_image && user.profile_image.url"
        :srcset="user.profile_image && user.profile_image.srcset"
        transition="none"
      />

      <v-chip
        class="user-type white--text ma-3 font-weight-medium"
        :color="user.color"
      >
        {{ user.user_type_display }}
        Since {{ memberSince }}
      </v-chip>
    </div>

    <v-card-text
      class="v-card-text py-2 overflow-hidden d-flex"
      :class="{ 'px-2': flat }"
    >
      <div style="max-width: 90%">
        <div
          class="username font-weight-bold text-body-1"
          :class="[user.textColor]"
          v-text="user.username"
        />

        <div
          class="followers text-caption font-weight-bold"
        >
          {{ numeral(user.follower_count).format('0,0') }} followers
        </div>

        <div
          class="
            location text-caption font-weight-bold
            grey--text text--lighten-1
          "
          v-text="user.location"
        />
      </div>

      <v-btn
        v-if="showFollow"
        color="primary"
        class="follow font-weight-medium"
        dark
        absolute
        right
        fab
        x-small
        :loading="pendingFollow"
        @click.stop.prevent="enforceUserEnabled(clickFollow)"
      >
        <v-icon>
          mdi-account-plus
        </v-icon>
      </v-btn>
    </v-card-text>
  </v-card>
</template>

<script>
import numeral from 'numeral';
import { mapActions } from 'vuex';

import { User } from 'pwa/models';

/**
 * @displayName User card
 */
export default {
  name: 'UserCard',

  props: {
    user: {
      type: User,
      required: true,
    },

    width: {
      type: [String, Number],
      default: null,
    },

    outlined: {
      type: Boolean,
      default: true,
    },

    flat: {
      type: Boolean,
      default: false,
    },

    /**
     * Optionally set follow method as dependency injection
     */
    followAction: {
      type: Function,
      default: null,
    },

    showFollow: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      pendingFollow: false,
    };
  },

  computed: {
    memberSince() {
      return new Date(this.user.date_joined).getFullYear();
    },
  },

  methods: {
    numeral,

    ...mapActions('auth', ['enforceUserEnabled']),

    async clickFollow() {
      this.$emit('follow-start', this.user);
      const follow = this.followAction || this.user.$follow.bind(this.user);
      this.pendingFollow = true;
      try {
        await follow();
        this.$emit('follow-complete', this.user);
      } catch (e) {
        this.$toast(
          `Could not follow ${this.user.username}`,
          this.$toast.ERROR,
        );
        throw e;
      } finally {
        this.pendingFollow = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.user-type {
  position: absolute;
  bottom: 0;
  right: 0;
  opacity: 0.9;
}

.v-card-text {
  line-height: 1.1em;
}

.location::after {
  content: '\00a0';
}

.username, .location {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.follow {
  right: 8px;
}
</style>
