import 'core-js';
import 'regenerator-runtime/runtime';

import VuexORM from '@vuex-orm/core';
import Vue from 'vue';
import VueGtag from 'vue-gtag';
import VueRouter from 'vue-router';
import Vuex from 'vuex';

import initSentry from 'common/sentry/init';
import { loadComponents } from 'pwa/components';
import { errorHandler } from 'pwa/exceptions';
import { TheAppShell } from 'pwa/pages';
import { loadPlugins } from 'pwa/plugins';
import vuetify from 'pwa/plugins/vuetify';
import { getRouter } from 'pwa/router';
import { createStore } from 'pwa/store';

// Load global sass modules
import './sass/main.scss';

if (window.debugFrontend) {
  // Allow Vue devtools to be enabled, even if not dev build.
  Vue.config.devtools = true;
}

Vue.use(VuexORM);
Vue.use(VueRouter);
Vue.use(Vuex);

(() => {
  const store = createStore();
  const router = getRouter(store);
  loadPlugins(Vue);
  loadComponents();

  window.vm = new Vue({
    el: '#app',
    render: (h) => h(TheAppShell),
    store,
    router,
    vuetify,
  });
  // Error handler assignment must come before other tooling
  Vue.config.errorHandler = errorHandler;

  initSentry(Vue);

  // Analytics must come after custom Vue errorHandler
  // GA4 gtag
  Vue.use(VueGtag, {
    config: { id: store.state.google_measurement_id },
  }, router);
})();
