import Vue from 'vue';

function makeComponent(name, svgPath) {
  // eslint-disable-next-line global-require, import/no-dynamic-require
  const icon = require(`${svgPath}?inline`);

  return Vue.component(name, {
    components: { icon },
    template: '<icon style="fill: currentColor" />',
  });
}

export default undefined;

const AllMyLinks = makeComponent('AllMyLinks', './allmylinks.svg');
const Facebook = makeComponent('Facebook', './facebook.svg');
const Google = makeComponent('Google', './google.svg');
const MFC = makeComponent('MFC', './mfc.svg');
const OnlyFans = makeComponent('OnlyFans', './onlyfans.svg');
const Twitter = makeComponent('Twitter', './twitter.svg');

export {
  AllMyLinks,
  Facebook,
  Google,
  MFC,
  OnlyFans,
  Twitter,
};
