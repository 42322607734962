<template>
  <v-bottom-sheet
    v-model="open"
    :width="width"
    scrollable
  >
    <template #activator="{ on, attrs }">
      <slot
        name="activator"
        :on="on"
        :attrs="attrs"
      />
    </template>

    <v-card
      class="text-center px-0"
      :height="height"
    >
      <v-card-title>
        <v-toolbar
          flat
          dense
        >
          <v-toolbar-title
            class="text-subtitle-1"
            v-text="title"
          />

          <v-spacer />
          <v-btn
            class="close-btn"
            icon
            @click="open = false"
          >
            <v-icon>
              mdi-close
            </v-icon>
          </v-btn>
        </v-toolbar>
      </v-card-title>
      <v-divider />

      <v-card-text
        class="mx-0 px-0 mb-7"
      >
        <v-list>
          <v-list-item
            v-for="user in users"
            :key="user.id"
            :to="user.$route"
            @click="open = false"
          >
            <v-list-item-avatar>
              <Avatar :user="user" />
            </v-list-item-avatar>

            <v-list-item-content>
              {{ user.username }}
            </v-list-item-content>
          </v-list-item>
        </v-list>
        <infinite-loading
          :distance="200"
          @infinite="loadNext"
        >
          <div slot="no-more" />
        </infinite-loading>
      </v-card-text>
    </v-card>
  </v-bottom-sheet>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'UserListSheet',

  props: {
    /**
     * Title / heading text
     */
    title: {
      type: String,
      default: '',
    },

    /**
     * Array of User instances
     */
    users: {
      type: Array,
      default: () => [],
    },

    /**
     * Height of the sheet
     */
    height: {
      type: [Number, String],
      default: '90vh',
    },

    /**
     * Sheet width
     */
    width: {
      type: [Number, String],
      default() {
        return this.$vuetify.breakpoint.thresholds.xs - 20;
      },
    },

    /**
     * loadNext callback for infinite loader
     */
    loadNext: {
      type: Function,
      default: async ($state) => {
        $state.loaded();
        $state.complete();
      },
    },
  },

  data() {
    return {
      open: false,
    };
  },

  watch: {
    open(open) {
      const action = open ? this.lockScrolling : this.unlockScrolling;
      action();
    },
  },

  beforeDestroy() {
    this.unlockScrolling();
  },

  methods: {
    ...mapActions('browser', ['lockScrolling', 'unlockScrolling']),
  },
};
</script>
