<template>
  <v-overlay
    opacity="0.75"
    :value="active"
    z-index="9999"
  >
    <v-progress-circular
      indeterminate
      size="64"
      color="primary"
    />
  </v-overlay>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

/**
 * A global loading state  & overlay for the PWA
 * @displayName The Page Loader
 */
export default {
  name: 'ThePageLoader',

  computed: mapGetters('loadingState', ['active']),

  watch: {
    /**
     * Disables scrolling when the overlay is open
     */
    active(value) {
      if (value) {
        this.lockScrolling();
      } else {
        this.unlockScrolling();
      }
    },
  },

  methods: mapActions('browser', ['lockScrolling', 'unlockScrolling']),
};
</script>
