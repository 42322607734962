<template>
  <div>
    <slot
      name="activator"
      :on="on"
      :loading="loading"
      :no-images="noImages"
    />
  </div>
</template>

<script>
import { convertToFullScreenResponsive } from 'pwa/utils/media';

export default {
  name: 'ThePromoGallery',

  props: {
    images: {
      type: Array,
      default: () => [],
    },
  },

  data: () => ({
    galleryImages: [],
    loading: false,
    noImages: false,
  }),

  created() {
    this.galleryImages = this.images;
  },

  methods: {
    async on() {
      let { galleryImages } = this;
      if (!galleryImages.length) {
        this.loading = true;
        try {
          galleryImages = await this.getImages();
        } finally {
          this.loading = false;
        }
        this.galleryImages = galleryImages;
      }
      if (!galleryImages.length) {
        this.noImages = true;
        return;
      }
      this.$store.dispatch('gallery/open', { images: galleryImages });
    },

    async getImages() {
      const client = this.$store.getters['api/client'];
      const url = this.$store.getters['api/routes'].promo_images;
      const response = await client.get(url);
      return response.data.map((image) => ({
        href: image.preview.url,
        srcset: image.preview.srcset,
        thumbnail: image.thumbnail.url,
        ...convertToFullScreenResponsive(
          image.preview.srcset,
          image.preview.width,
          image.preview.height,
        ),
      }));
    },
  },
};
</script>
