<template>
  <v-btn
    active-class="primary--text"
    :to="to"
    :value="value"
    @click="$emit('click')"
  >
    <div class="nav-label">
      Notifications
    </div>

    <BadgedIcon
      icon="mdi-bell"
      :unread-count="unreadCount"
      :acknowledged="acknowledged"
    />
  </v-btn>
</template>

<script>
import BadgedIcon from 'pwa/components/BadgedIcon';
import { Notification } from 'pwa/models';

export default {
  name: 'TheNotificationButton',

  components: { BadgedIcon },

  props: {
    /**
     * The vue-router route name to determine selection
     */
    value: {
      required: false,
      default: null,
      type: String,
    },

    /**
     * The router push value
     */
    to: {
      required: false,
      type: [Object, String],
      default: null,
    },
  },

  computed: {
    acknowledged: () => Notification.getters('unreadAcknowledged'),
    unreadCount: () => Notification.getters('unreadCount'),
  },
};
</script>
