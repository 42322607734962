<template>
  <SgSheet
    v-model="open"
    scrollable
    @click:outside="save"
    @open="setFilters"
  >
    <template #activator="{ on }">
      <v-btn
        id="open-filter-sheet-btn"
        width="36"
        height="36"
        icon
        v-on="on"
      >
        <v-badge
          offset-x="5"
          offset-y="5"
          dot
          :value="showBadge"
        >
          <v-icon>mdi-tune-variant</v-icon>
        </v-badge>
      </v-btn>
    </template>

    <template #title>
      Filters
    </template>

    <template #titleRight="{ close }">
      <v-btn
        class="save"
        block
        text
        color="primary"
        small
        @click="save(close)"
      >
        Done
      </v-btn>
    </template>

    <v-divider class="mb-3" />

    <div class="overline">
      Show
    </div>

    <div
      v-for="(filter, index) in filters"
      :id="filter.id"
      :key="index"
    >
      <v-checkbox
        v-model="selectedFilters"
        class="py-0 my-2"
        hide-details
        :value="filter.id"
        :label="filter.text"
      />
    </div>

    <v-divider class="my-4" />

    <div class="overline">
      Sort by
    </div>

    <v-radio-group
      :value="computedOrderingFilter"
      class="my-0"
      @change="(value) => selectedOrderingFilter = value"
    >
      <v-radio
        v-for="filter in orderingFilters"
        :id="filter.id"
        :key="filter.id"
        :value="filter.id"
        :label="filter.text"
      />
    </v-radio-group>
  </SgSheet>
</template>

<script>
import { mapGetters } from 'vuex';

import { Feed } from 'pwa/models';

const SOTDS = { id: 'sgs', text: 'SuicideGirls' };
const HOPEFULS = { id: 'hopefuls', text: 'Hopefuls' };
const FOLLOWING = { id: 'following', text: 'Users I\'m Following' };

export default {
  name: 'TheIndexPageFilterSheet',

  data() {
    return {
      open: false,
      orderingFilters: [
        {
          id: 'latest',
          text: 'Most recent',
        },
        {
          id: 'mostLovedThisWeek',
          text: 'Most loved this week',
        },
        {
          id: 'mostLovedThisMonth',
          text: 'Most loved this month',
        },
        {
          id: 'mostLovedThisYear',
          text: 'Most loved this year',
        },
        {
          id: 'mostLovedEver',
          text: 'Most loved all time',
        },
      ],
      selectedFilters: [],
      selectedOrderingFilter: Feed.getters('selectedOrderingFilter'),
    };
  },

  computed: {
    /**
     * Display the badge when the filters have been changed by
     * user so they know that they are not viewing the default
     * feed.
     */
    showBadge() {
      if (this.computedOrderingFilter !== 'latest') {
        return true;
      }
      const selectedUserFilters = this.isSotd
        ? this.selectedSotdUserTypeFilters
        : this.selectedUserTypeFilters;

      return this.filters.length !== selectedUserFilters.length;
    },

    isSotd() {
      return this.$route.name === 'index:sotds';
    },

    filters() {
      const filters = [SOTDS];
      if (!this.isSotd) {
        filters.push(HOPEFULS);
      }
      if (this.currentUser.is_authenticated) {
        filters.push(FOLLOWING);
      }
      return filters;
    },

    userTypes() {
      return this.filters.map(({ id }) => id);
    },

    ...mapGetters('auth', ['currentUser']),
    ...mapGetters('entities/feeds', [
      'selectedUserTypeFilters',
      'selectedSotdUserTypeFilters',
      'userTypeFilters',
    ]),
    ...mapGetters({
      computedOrderingFilter: 'entities/feeds/selectedOrderingFilter',
    }),
  },

  methods: {
    async save(closeCallback) {
      let { selectedFilters } = this;
      if (!selectedFilters.length) {
        selectedFilters = this.userTypes;
      }

      const action = this.isSotd
        ? 'setSotdUserTypeFilters'
        : 'setUserTypeFilters';
      await Feed.dispatch(action, selectedFilters);
      await Feed.dispatch('setOrderingFilter', this.selectedOrderingFilter);
      if (closeCallback) {
        // click:outside triggers concurrent with close, so no callback.
        closeCallback();
      }
    },

    setFilters() {
      this.selectedFilters = this.isSotd
        ? this.selectedSotdUserTypeFilters
        : this.selectedUserTypeFilters;
    },
  },
};
</script>
