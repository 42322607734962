<template>
  <v-expand-transition>
    <v-system-bar
      v-if="!isOnline"
      id="offline-bar"
      class="d-flex justify-center font-weight-bold"
      app
      window
      color="error"
    >
      No network connection
      <v-icon right>
        mdi-power-plug-off
      </v-icon>
    </v-system-bar>
  </v-expand-transition>
</template>

<script>
import { mapGetters } from 'vuex';

/**
 * Notifies the user that the application is operating in offline mode.
 * @displayName The Offline Bar
 */
export default {
  name: 'TheOfflineBar',
  computed: mapGetters('browser', ['isOnline']),
};
</script>

<style lang="scss">
#offline-bar {
  z-index: 6;
}
</style>
