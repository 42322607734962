<template>
  <v-bottom-sheet
    :value="displayRenewSubscriptionSheet"
    persistent
  >
    <v-sheet
      id="renew-subscription-sheet"
      class="text-center"
    >
      <div class="container">
        <div class="text-right">
          <v-btn
            id="renew-subscription-sheet-close"
            large
            icon
            @click="toggleRenewSubscriptionSheet(false)"
          >
            <v-icon>
              mdi-close
            </v-icon>
          </v-btn>
        </div>
        <div class="text-h4 text-uppercase font-weight-bold py-3">
          Your subscription has expired.
        </div>

        <v-btn
          id="renew-subscription-btn"
          :to="{ name: 'register-payment' }"
          large
          class="text-uppercase text-subtitle-1 font-weight-bold my-3"
          color="primary"
          rounded
          @click="toggleRenewSubscriptionSheet(false)"
        >
          <div class="px-6">
            Renew now
          </div>
        </v-btn>
      </div>
    </v-sheet>
  </v-bottom-sheet>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

/**
 * @displayName The renew subscription sheet
 */
export default {
  name: 'TheRenewSubscriptionSheet',

  computed: {
    ...mapGetters('auth', [
      'displayRenewSubscriptionSheet',
    ]),
  },

  methods: {
    ...mapActions('auth', [
      'join',
      'toggleRenewSubscriptionSheet',
    ]),
  },
};
</script>

<style lang="scss" scoped>
.container {
  max-width: 450px;
}
</style>
