<template>
  <div>
    <v-navigation-drawer
      :value="profileDrawerVisible"
      fixed
      right
      temporary
      @input="toggleProfileDrawer"
    >
      <template #prepend>
        <v-list-item two-line>
          <v-list-item-avatar>
            <img :src="currentUser.avatar">
          </v-list-item-avatar>

          <v-list-item-content>
            <v-list-item-title>{{ currentUser.username }}</v-list-item-title>
            <v-list-item-subtitle>Logged In</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </template>

      <v-divider />

      <v-list
        dense
      >
        <v-list-item
          :to="currentUser.$route"
          @click.native="toggleProfileDrawer(false)"
        >
          <v-list-item-icon>
            <v-icon>mdi-account</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>View profile</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item
          :to="{ name: 'preferences' }"
          @click.native="toggleProfileDrawer(false)"
        >
          <v-list-item-icon>
            <v-icon>mdi-cog</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>Account preferences</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item
          class="groups"
          href="https://www.suicidegirls.com/groups/"
          target="_blank"
          @click.native="toggleProfileDrawer(false)"
        >
          <v-list-item-icon>
            <v-icon>mdi-forum</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>Groups</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item @click="$store.dispatch('auth/$logout')">
          <v-list-item-icon>
            <v-icon>mdi-logout</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>Logout</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'TheProfileDrawer',

  computed: {
    ...mapGetters('auth', ['currentUser']),
    ...mapGetters('entities/users', ['profileDrawerVisible']),
  },

  methods: {
    ...mapActions('entities/users', ['toggleProfileDrawer']),
  },
};
</script>
