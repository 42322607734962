<template>
  <v-bottom-sheet
    :value="displayMembersOnlySheet"
    persistent
  >
    <v-sheet
      id="members-only-sheet"
      class="text-center pb-10"
    >
      <div class="container">
        <div class="text-right">
          <v-btn
            id="member-sheet-close"
            large
            icon
            @click="toggleMembersOnlySheet(false)"
          >
            <v-icon>
              mdi-close
            </v-icon>
          </v-btn>
        </div>

        <div class="py-3">
          <img
            :src="logo"
            class="mx-auto"
            width="100"
            height="129"
          >
          <div class="py-3 title text-uppercase primary--text font-weight-bold">
            That feature is for<br>members only.
          </div>

          <div class="text-body-2 font-weight-medium">
            Create a profile and access over 1.7 million photos and premium
            content on SuicideGirls.com
          </div>
        </div>

        <v-btn
          id="member-sheet-join"
          :to="{ name: 'register', query: { next: $route.fullPath }}"
          large
          class="text-uppercase text-subtitle-1 font-weight-bold my-3"
          color="primary"
          block
          rounded
          @click="toggleMembersOnlySheet(false)"
        >
          <div class="px-6">
            Become a member today
          </div>
        </v-btn>

        <v-btn
          id="member-sheet-login"
          :to="{ name: 'login', query: { next: $route.fullPath }}"
          large
          class="font-weight-bold"
          rounded
          outlined
          block
          color="primary"
          @click="toggleMembersOnlySheet(false)"
        >
          Log in
        </v-btn>
      </div>
    </v-sheet>
  </v-bottom-sheet>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

import logo from '@/../suicidegirls/static/img/logo-solo.svg';

/**
 * @displayName The members only sheet
 */
export default {
  name: 'TheMembersOnlySheet',

  data() {
    return { logo };
  },

  computed: {
    ...mapGetters('auth', [
      'displayMembersOnlySheet',
    ]),
  },

  methods: {
    ...mapActions('auth', ['toggleMembersOnlySheet']),
  },
};
</script>

<style lang="scss" scoped>
.container {
  max-width: 450px;
}
</style>
