<template>
  <div class="filters flex-nowrap mt-0 overflow-hidden d-flex">
    <v-chip-group
      id="content-filter"
      class="overflow-hidden align-center flex-grow-1"
      mandatory
      :show-arrows="!isMobile"
    >
      <v-chip
        v-for="(filter, index) in contentFilters"
        :key="index"
        :text="filter.text"
        :to="filter.to"
        exact
        class="filter mr-1 font-weight-medium my-0"
        small
        outlined
      >
        <span>
          {{ filter.text }}
        </span>
      </v-chip>
    </v-chip-group>

    <div
      :class="{ 'pl-1': isMobile }"
      class="align-center"
    >
      <TheIndexPageFilterSheet />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import logo from '@/../suicidegirls/static/img/logo-solo.svg';

export default {
  name: 'TheIndexFilters',

  props: {
    app: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      contentFilters: [
        {
          text: 'All',
          to: { name: 'index' },
        },
        {
          text: 'Sets of the Day',
          to: { name: 'index:sotds' },
        },
        {
          text: 'Member Review',
          to: { name: 'index:memberReview' },
        },
        {
          text: 'Photos',
          to: { name: 'index:photos' },
        },
        {
          text: 'Videos',
          to: { name: 'index:videos' },
        },
      ],
      logo,
    };
  },

  computed: {
    ...mapGetters('auth', ['currentUser']),
    ...mapGetters('browser', ['isMobile']),
    ...mapGetters('entities/streams', ['showBanner']),
  },
};
</script>

<style lang="scss" scoped>
.filter {
  // Add custom background colors to outlined chips
  &.v-chip.v-chip--outlined.v-chip.v-chip {
    background-color: map-get($grey, 'lighten-3') !important;

    &.v-chip--active {
      background-color: map-get($grey, 'darken-3') !important;
      color: map-get($shades, 'white');
    }
  }
}
</style>
