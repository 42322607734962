<template>
  <div
    class="video-wrapper overflow-hidden"
    :style="{
      '--poster-img': `url(${poster})`,
    }"
  >
    <div class="background" />
    <div class="container-max-width mx-auto py-0 my-0">
      <VideoPlayer
        class="promo-video"
        :options="joinVideoOptions"
      />
    </div>
  </div>
</template>

<script>
import poster from '@/../suicidegirls/static/img/video-poster.jpg';

export default {
  name: 'ThePromoVideo',

  data() {
    return {
      poster,
      joinVideoOptions: {
        poster,
        preload: 'none',
        controls: true,
        aspectRatio: '3:1',
        fill: true,
        sources: [{
          src: (
            'https://d1a0n9gptf7ayu.cloudfront.net/videos/'
            + '29f37410-5d2c-4b0f-8db0-45bda2d9dfd5-1280x720.mp4'
          ),
          type: 'video/mp4',
        }],
      },
    };
  },
};
</script>

<style lang="scss" scoped>
::v-deep .promo-video {
  .vjs-poster {
    background-position: 0 47%;
    background-repeat: no-repeat;
    background-size: cover;
  }
  &.video-js {
    background: transparent !important;
    &.vjs-ended .vjs-poster {
      display: block;
    }
  }
}

.video-wrapper {
  position: relative;

  .background {
    background-image: var(--poster-img);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    filter: blur(10px) opacity(90%);
    height: 100%;
    position: absolute;
    top: 0;
    width: 100%;
  }
}
</style>
